"use client";

import { PropsWithChildren } from "react";
import { AppProgressBar as ProgressBar } from "next-nprogress-bar";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "@/theme";
import { Config } from "@/graphql/generated/graphql";
import { SessionProvider } from "next-auth/react";
import { ApolloWrapper } from "./ApolloWrapper";
import { ConfigProvider } from "./ConfigProvider";

type Props = {
  config: Config;
};

const Providers: React.FC<PropsWithChildren<Props>> = ({ config, children }) => (
  <ApolloWrapper>
    <ConfigProvider config={config}>
      <ChakraProvider theme={theme}>
        <SessionProvider>{children}</SessionProvider>
      </ChakraProvider>
    </ConfigProvider>
    <ProgressBar height="4px" color="#e2ab00" options={{ showSpinner: false }} shallowRouting />
  </ApolloWrapper>
);

export default Providers;
